@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");
footer {
  background: #222;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
}
footer .footer__columns {
  padding: 40px 0 0 0;
}
footer .footer__columns .row--cols > div {
  margin-bottom: 20px;
  font-size: 14px;
}
footer .footer__columns .row--cols > div h5 {
  margin-top: 1rem;
}
footer .footer__columns .col-collapse .collapse {
  display: inherit;
}
@media (max-width: 767px) {
  footer .footer__columns {
    padding: 20px 0;
  }
  footer .footer__columns .row--cols > div {
    margin-bottom: 0;
    border-bottom: 1px solid #444;
  }
  footer .footer__columns .col-collapse ul {
    margin-bottom: 0;
  }
  footer .footer__columns .col-collapse .collapse {
    display: none;
  }
  footer .footer__columns .col-collapse .collapse.show {
    display: block;
  }
  footer .footer__columns .col-collapse .title {
    font-size: 1rem;
  }
  footer .footer__columns .col-collapse .title:hover {
    cursor: pointer;
  }
  footer .footer__columns .col-collapse .title .fa-angle-up,
  footer .footer__columns .col-collapse .title[aria-expanded=true] .fa-angle-down {
    display: none;
  }
  footer .footer__columns .col-collapse .title {
    margin-bottom: 10px;
  }
  footer .footer__columns .col-collapse .title[aria-expanded=true] .fa-angle-up {
    display: block;
  }
  footer .footer__columns .col-collapse .navbar-toggler {
    display: inline-block;
    padding: 0;
  }
  footer .footer__columns .row {
    margin-right: 0;
    margin-left: 0;
  }
}
footer .footer__columns .footer__widget {
  color: #ffffff !important;
}
footer .footer__columns .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
footer .footer__columns .footer__widget ul li {
  display: table-row;
  color: #666;
}
footer .footer__columns .footer__widget ul li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
}
footer .footer__columns .footer__widget ul li a {
  color: #ccc;
}
footer .footer__columns .footer__widget a {
  color: #ffffff !important;
}
footer .footer__columns .footer__widget .footer__social i {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.2s ease;
}
footer .footer__columns .footer__widget .footer__social i:hover, footer .footer__columns .footer__widget .footer__social i:focus {
  color: rgb(255, 255, 255);
}
footer .footer__columns .footer__widget .btn-outline-light {
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  font-size: 80%;
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: transparent !important;
}
footer .footer__columns .footer__widget .btn-outline-light:hover, footer .footer__columns .footer__widget .btn-outline-light:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #ffffff !important;
}
footer .footer__priceinfo {
  background: #333 !important;
  color: #fff;
  text-align: center;
}
footer .footer__priceinfo p {
  margin-bottom: 0;
  font-size: 80%;
  color: #999;
  line-height: 17px;
}
footer .footer__tags {
  text-align: center;
  background: #1a171b !important;
  font-size: 14px;
  line-height: 21px;
  padding: 40px 0 20px 0;
  margin-top: 20px;
}
footer .footer__tags ul {
  display: block;
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
}
footer .footer__tags ul li {
  list-style: none;
  color: #666;
}
footer .footer__tags ul li a {
  color: #999;
}
footer h3 {
  font-size: 21px;
  color: #ffffff !important;
}
footer h5 {
  display: block;
  margin: 0 0 10px;
  color: #ffffff !important;
}
@media (max-width: 565px) {
  footer .bottom__logos {
    text-align: left;
  }
  footer .bottom__logos img {
    max-width: 43px;
    margin-right: 15px;
  }
}

.bottom__logos {
  padding-bottom: 0px;
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.bottom__logos img {
  max-height: 50px;
  width: auto;
  max-width: 55px;
  margin-right: 15px;
}
.bottom__logos .payment__logos {
  display: inline;
}
.bottom__logos .payment__logos img {
  max-height: 30px;
  margin-bottom: 10px;
}

.bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
.bottomnav ul li {
  display: inline-block;
}
.bottomnav ul li + li:before {
  content: "-";
  padding: 0 6px;
  display: inline-block;
}

.grecaptcha-badge {
  visibility: hidden;
}