@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");
#reviewform {
  margin-top: 20px;
}
#reviewform .wh-form__buttongroup {
  max-width: 300px;
}
#reviewform .remark {
  border: 1px dotted #aaa;
  padding: 10px;
  margin: 40px 0 20px;
}

.reviewbtn {
  margin-bottom: 20px;
}

#showreviewstats {
  color: #e31c76 !important;
  cursor: pointer;
}
#showreviewstats:hover {
  color: inherit;
}

#reviewpagetotals {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#reviewpagetotals .rating-stars {
  margin: 0 4px 10px 10px;
}
#reviewpagetotals .mm-rating-txt {
  line-height: 19px;
  margin-top: 0;
  margin-bottom: 10px;
}

#rating-stats {
  overflow: hidden;
  height: 0;
  line-height: 19px;
  transition: height 0.3s;
}
#rating-stats > div {
  overflow: hidden;
}
#rating-stats.show {
  height: 130px;
}
#rating-stats .count {
  display: inline-block;
  padding: 1px 0 0 7px;
}
#rating-stats .count:before {
  content: "(";
}
#rating-stats .count:after {
  content: ")";
}

.reviewitem__balloon {
  background: #f5f5f3 !important;
  padding: 10px 10px 0;
  font-style: italic;
  overflow: hidden;
}

.reviewitem__balloonarrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f5f5f3 !important;
  margin: 0 0 0 30px;
}

.reviewitem__author {
  font-weight: 700;
}

.reviewitem__starcontainer {
  display: inline-block;
  position: relative;
  height: 22px;
}

#rating-tooltip .rating-stars {
  position: relative;
  display: inline-block;
  width: 117px;
  height: 22px;
}

li.webshop-pagenav {
  list-style: none;
  display: inline-block;
  border: 1px solid #ddd;
  padding: 5px 10px;
  text-align: center;
}

li.webshop-pagenav + li.webshop-pagenav {
  border-left: none;
}

li.webshop-pagenav--current {
  background: #e31c76 !important;
  color: #fff;
}

li.webshop-pagenav:not(.webshop-pagenav--current):hover {
  background: #f5f5f3 !important;
}

li.webshop-pagenav:not(.webshop-pagenav--current):hover a {
  text-decoration: none;
}

li.webshop-pagenav:first-child:not(.webshop-pagenav--first) .webshop-pagebuton__pagenr {
  display: none;
}
li.webshop-pagenav:first-child:not(.webshop-pagenav--first):before {
  content: "...";
}

li.webshop-pagenav:last-child:not(.webshop-pagenav--last) .webshop-pagebuton__pagenr {
  display: none;
}
li.webshop-pagenav:last-child:not(.webshop-pagenav--last):before {
  content: "...";
}