@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");
@import "~dompack/browserfix/reset.css";
html {
  scroll-behavior: smooth;
}

body {
  background: #f5f5f3 !important;
}

body, main.site-wrapper {
  min-height: 100vh;
}

.wh-anchor {
  scroll-margin-top: 250px;
  top: -200px;
}

.main--margintop {
  margin-top: 1.5rem;
}

section {
  padding: 20px 0;
}

.page--blogitem .headerimage,
.page--rtd .headerimage {
  background: #fff;
  text-align: center;
  margin: -20px;
}
.page--blogitem .headerimage img,
.page--rtd .headerimage img {
  border-radius: 0.25rem;
}

.site__content {
  flex: 1 1 auto;
  position: relative;
}

.bg-dark {
  background-color: #1a171b !important;
}
.bg-dark h2.heading2,
.bg-dark h2.sectiontitle,
.bg-dark h2,
.bg-dark p,
.bg-dark li,
.bg-dark a {
  color: #fff;
}

.bg-gradient {
  background-image: linear-gradient(to right, #dc006b, #a80051) !important;
}
.bg-gradient h2.heading2,
.bg-gradient h2.sectiontitle,
.bg-gradient h2,
.bg-gradient p,
.bg-gradient li,
.bg-gradient a {
  color: #fff;
}

.bg-light {
  background-color: #f5f5f3 !important;
}

.bg-extralight {
  background-color: #f9f9f9 !important;
}

.bg-primary,
.bg-pink {
  background-color: #e31c76 !important;
}
.bg-primary h2.heading2,
.bg-primary h2.sectiontitle,
.bg-primary h2,
.bg-primary p,
.bg-primary li,
.bg-primary a,
.bg-pink h2.heading2,
.bg-pink h2.sectiontitle,
.bg-pink h2,
.bg-pink p,
.bg-pink li,
.bg-pink a {
  color: #fff;
}

.bg-secondary {
  background-color: #ee742a !important;
}
.bg-secondary-light {
  background-color: #eff4f7 !important;
}

.bg-green {
  background-color: #59b136 !important;
}

.bg-grey {
  background-color: #555555 !important;
}
.bg-grey h2.heading2,
.bg-grey h2.sectiontitle,
.bg-grey h2,
.bg-grey p,
.bg-grey li,
.bg-grey a {
  color: #fff;
}

.rounded-lg {
  border-radius: 15px;
}

.form-control {
  font-weight: 300;
}

main .pageimage {
  margin-bottom: 20px;
}
main .pageimage > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.contents--spaceright {
  padding-right: 30%;
  position: relative;
}

.contents__imageright {
  width: calc(30% - 20px);
  min-height: 20px;
  position: absolute;
  top: 7px;
  right: 0;
}
.contents__imageright > img {
  width: 100%;
  height: auto;
}

.webshop-checkout {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .contents--spaceright {
    padding-right: 0;
  }
  .contents__imageright {
    display: none;
  }
}
.rating-stars {
  background-image: url(web/img/stars-review-grey.png);
  display: inline-block;
  height: 22px;
  position: relative;
  width: 117px;
  clear: both;
}
.rating-stars > .inner {
  background-image: url(web/img/stars-review-gold.png);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pagetags {
  margin: 30px 0;
}
.pagetags ul {
  list-style-type: none;
  margin: 0 0 0 -6px;
  padding: 0;
}
.pagetags ul li {
  display: inline-block;
  margin-bottom: 6px;
  margin-left: 6px;
}
.pagetags ul li a {
  display: inline-block;
  color: #333;
  border-color: #fff;
  background-color: #fff;
  padding: 6px 12px;
  text-decoration: none;
  transition: all 0.2s ease;
  border-radius: 0.25rem;
}
.pagetags ul li a:hover {
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

@media (max-width: 576px) {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb {
  background: none;
  padding-left: 0;
  margin: 1rem 0 5px 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumb li {
  display: inline-block;
}
.breadcrumb a,
.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  color: #999;
  font-size: 14px;
}

.contentarea {
  position: relative;
  z-index: 0;
}

button {
  cursor: pointer;
}

.btn {
  cursor: pointer;
  font-weight: 300;
  white-space: normal;
  border-radius: 25rem;
}

.btn-lg {
  padding: 0.7rem 1.3rem;
  font-size: 1rem;
}

.btn-lg.btn-outline-light {
  border-width: 2px;
}

.btn-primary,
.btn-blue {
  background-color: #e31c76 !important;
  border-color: #e31c76 !important;
  color: #fff !important;
  border-color: #e31c76 !important;
}

.btn-outline-primary {
  color: #e31c76 !important;
  border-color: #e31c76 !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-blue:hover,
.btn-blue:focus {
  background-color: #fc1e82 !important;
  border-color: #fc1e82 !important;
  text-decoration: none;
  color: #fff;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: #e31c76 !important;
  border-color: #e31c76 !important;
  text-decoration: none;
  color: #fff;
}

.btn-secondary {
  background-color: #59b136 !important;
  border-color: #59b136 !important;
  color: #ffffff !important;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #66c93e !important;
  border-color: #66c93e !important;
  text-decoration: none;
}

.btn-light {
  background-color: #f5f5f3 !important;
  border-color: #f5f5f3 !important;
  color: #1a171b !important;
}

.btn-light:hover,
.btn-light:focus {
  background-color: #f9f9f9 !important;
  border-color: #f9f9f9 !important;
  text-decoration: none;
}

.btn-dark {
  background-color: #1a171b !important;
  border-color: #1a171b !important;
  color: #fff;
  border-radius: 0;
}

.btn-dark:hover,
.btn-dark:focus {
  background-color: #1a171b !important;
  border-color: #1a171b !important;
  text-decoration: none;
}

.btn-secondary-light {
  background-color: #eff4f7 !important;
  border-color: #f5f5f3 !important;
  color: #1a171b !important;
}

.btn-secondary-light:hover,
.btn-secondary-light:focus {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  text-decoration: none;
}

.btn-outline-light {
  background-color: transparent !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.btn-outline-light:hover,
.btn-outline-light:focus {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: #ffffff !important;
  color: #ffffff !important;
  text-decoration: none;
}

.btn-success {
  background-color: #59b136 !important;
  border-color: #59b136 !important;
  color: #ffffff !important;
}

.btn-success:hover,
.btn-success:focus {
  background-color: #59b136 !important;
  border-color: #59b136 !important;
  text-decoration: none;
}

.btn-info {
  background-color: #4185d6;
}

.btn-d-none {
  display: none;
}

.text-primary {
  color: #e31c76 !important;
}

.text-dark {
  color: #1a171b !important;
}

.card-body {
  padding: 1rem;
}

#sidebar aside {
  margin: 0 0 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}
#sidebar aside.widget-login {
  background: #fff;
  margin: 0 0 20px;
  padding: 20px;
  overflow: hidden;
}
#sidebar aside .aside__title {
  font-size: 21px;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  line-height: 1.1;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.1;
}

.mydialog {
  background: white;
  padding: 20px;
}
.mydialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mydialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.mydialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.mydialog__modalbg {
  z-index: 2;
}

#sidenav {
  background: #1a171b !important;
  padding: 20px;
}
#sidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#sidenav > ul {
  margin-top: -5px;
}
#sidenav > ul li.active {
  font-family: "Titillium Web", sans-serif;
  font-size: 21px;
  font-weight: 600;
}
#sidenav li {
  display: block;
}
#sidenav a {
  display: block;
  text-decoration: none;
  line-height: 150%;
  color: #fff;
  font-size: 14px;
  padding: 0 0 10px 0;
  font-family: "verdana", "arial", "helvetica", sans-serif;
  font-weight: 500;
}
#sidenav a:hover {
  text-decoration: underline;
}
#sidenav > ul > li > a {
  background: #1a171b !important;
  display: block;
  padding: 10px 0 10px 0px;
  color: #fff;
}
#sidenav > ul > li > ul > li li {
  padding-left: 15px;
}
#sidenav li > ul a {
  padding: 1px 0;
  position: relative;
  color: #fff;
  font-size: 15px;
}

.card {
  border-radius: 0.7rem;
}

.contents__catpath {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
  color: #a89895;
}

.contents__catpathelt {
  display: inline-block;
}

.contents__catpathelt + li::before {
  padding: 0 5px;
  color: #ccc;
  content: "/ ";
}

.summary .pagedescription {
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 30px;
}
.summary .webshop-blog__item .card-title {
  font-size: 1.5rem;
}
.summary .webshop-blog__item a {
  color: #1a171b;
}
.summary .webshop-blog__item a:hover {
  text-decoration: none;
}

.webshop-account__orders {
  width: 100%;
  margin: 0 0 20px;
  border-bottom: 1px solid #f0f0f0;
}
.webshop-account__orders .webshop-account__ordertotal:before {
  display: inline-block;
  content: "€";
  padding-right: 5px;
}
.webshop-account__orders td, .webshop-account__orders th {
  padding: 5px 20px 5px 0;
  white-space: nowrap;
}
.webshop-account__orders .webshop-account__ordertotal {
  text-align: right;
  width: 100px;
}
.webshop-account__orders .webshop-account__orderrow:nth-child(2n-1) td {
  background-color: #fafafa;
}

.webshop-account__fullbillingaddress .webshop-account__ordertotal:before {
  content: "€ ";
}

.btn-default {
  color: #999;
  border-color: #ccc;
  transition: all 0.5s;
}

.btn-default:hover,
.btn-default:focus {
  background-color: #d9534f;
  color: #fff;
  border-color: #d9534f;
}

.submenu:not(.d-none) {
  display: none;
}

#leftSidebar,
#filterSideNav {
  border-top: 0;
}
#leftSidebar.offcanvas:not(.show) ul,
#filterSideNav.offcanvas:not(.show) ul {
  display: none;
}
#leftSidebar .close-btn,
#filterSideNav .close-btn {
  display: block;
  float: right;
  width: 42px;
  height: 41px;
  display: block;
  padding: 5px 5px;
}
#leftSidebar .close-btn:before,
#filterSideNav .close-btn:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #ee742a !important;
  display: block;
  transition: all 300ms;
  transform: translateY(-1px) rotate(45deg);
}
#leftSidebar .close-btn:after,
#filterSideNav .close-btn:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  margin-top: -2px;
  background: #ee742a !important;
  display: block;
  transform: translateY(-1px) rotate(-45deg);
  transition: all 300ms;
}
#leftSidebar .webshop-search input,
#filterSideNav .webshop-search input {
  border-top-left-radius: 25rem;
  border-bottom-left-radius: 25rem;
}
#leftSidebar .webshop-search .btn,
#filterSideNav .webshop-search .btn {
  border-top-right-radius: 25rem;
  border-bottom-right-radius: 25rem;
}
#leftSidebar ul,
#filterSideNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#leftSidebar .offcanvas-body,
#filterSideNav .offcanvas-body {
  background: #1a171b !important;
  overflow-y: auto;
}
#leftSidebar .offcanvas-body .nav-link,
#filterSideNav .offcanvas-body .nav-link {
  font-size: 14px;
  padding: 0.5rem 1rem;
}
#leftSidebar .offcanvas-body .nav-link.menu-subtitle,
#filterSideNav .offcanvas-body .nav-link.menu-subtitle {
  color: #e31c76 !important;
  text-transform: uppercase;
  font-weight: 700;
}
#leftSidebar .offcanvas-body a,
#leftSidebar .offcanvas-body h3,
#filterSideNav .offcanvas-body a,
#filterSideNav .offcanvas-body h3 {
  color: #fff;
}
#leftSidebar .has-submenu,
#filterSideNav .has-submenu {
  position: relative;
}
#leftSidebar .has-submenu .nav-link,
#filterSideNav .has-submenu .nav-link {
  display: block;
  margin-right: 42px;
  font-size: 14px;
}
#leftSidebar .has-submenu > .submenu-toggle,
#filterSideNav .has-submenu > .submenu-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: block;
  float: right;
  width: 45px;
  height: 45px;
  border-left: 1px solid #444;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  transition: all 200ms ease-out;
  box-sizing: border-box;
}
#leftSidebar .has-submenu > .submenu-toggle:last-child,
#filterSideNav .has-submenu > .submenu-toggle:last-child {
  border-top: none;
}
#leftSidebar .has-submenu > .submenu-toggle:before,
#filterSideNav .has-submenu > .submenu-toggle:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #e31c76 !important;
  display: block;
  transition: all 300ms;
}
#leftSidebar .has-submenu > .submenu-toggle:after,
#filterSideNav .has-submenu > .submenu-toggle:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  background: #e31c76 !important;
  display: block;
  transform: translateY(-1px) rotate(-90deg);
  transition: all 300ms;
}
#leftSidebar .has-submenu > .submenu-toggle:hover,
#filterSideNav .has-submenu > .submenu-toggle:hover {
  cursor: pointer;
}
#leftSidebar .has-submenu > .submenu-toggle.collapse--open:before,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:before {
  transform: rotate(-180deg);
}
#leftSidebar .has-submenu > .submenu-toggle.collapse--open:after,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:after {
  transform: translateY(-1px) rotate(-360deg);
  opacity: 0;
}
#leftSidebar .has-submenu .has-submenu > .nav-link,
#filterSideNav .has-submenu .has-submenu > .nav-link {
  padding: 0.5rem 1rem !important;
}
#leftSidebar ul li,
#filterSideNav ul li {
  border-bottom: 1px solid #444;
  margin-top: -1px;
}
#leftSidebar ul li ul li,
#filterSideNav ul li ul li {
  border: none;
}
#leftSidebar ul.active li,
#filterSideNav ul.active li {
  border: none;
}
#leftSidebar ul li.active,
#filterSideNav ul li.active {
  border-top: 0;
}
#leftSidebar ul.submenu,
#filterSideNav ul.submenu {
  margin-left: 0.5rem;
}
#leftSidebar ul.submenu .nav-link,
#filterSideNav ul.submenu .nav-link {
  padding: 0.2rem 1rem;
}
#leftSidebar .btn-link,
#leftSidebar .btn-link:active,
#leftSidebar .btn-link:focus,
#leftSidebar .btn-link:hover,
#filterSideNav .btn-link,
#filterSideNav .btn-link:active,
#filterSideNav .btn-link:focus,
#filterSideNav .btn-link:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 5px;
}

@media (max-width: 576px) {
  .webshop--isproductpage main {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  #mainSideNav {
    display: none;
  }
}
.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-3 {
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
}

.container-xl .container-xl {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.container-xl .product_listing {
  padding-right: 15px;
  padding-left: 15px;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

#custom-notification {
  text-align: center;
  background: #e31c76 !important;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
  height: 0;
  position: relative;
  overflow: hidden;
  max-height: 100vh;
}
#custom-notification .container {
  padding: 10px 0;
}
#custom-notification .container p {
  margin-bottom: 0;
}
#custom-notification a {
  text-decoration: underline;
  color: #fff;
  display: inline-block;
  margin-left: 5px;
}
#custom-notification button {
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  color: #fff;
}