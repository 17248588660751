.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}
/* productfilter(s) */
.webshop-products-mini .product_listing
{
  position: relative;
}
.webshop-products-mini .productsgrid_fade
{
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  background-image: linear-gradient(270deg,#f5f5f3,rgba(255,255,255,0) 30%),linear-gradient(90deg,#f5f5f3,rgba(255,255,255,0) 33%);
}
.webshop-products-mini .glide__arrow
{
  box-shadow: none;
  border: 0;
  font-size: 50px;
}
.webshop-products-mini .glide__arrow--left
{
  left: 0;
}
.webshop-products-mini .glide__arrow--right
{
  right: 0;
}
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  background: #e31c76;
  border-radius: 15px;
  margin: 20px 0 0 0;
  padding: 20px;
  overflow: hidden;
}
.widget-productfilter--hidden
{
  display: none;
}
.productfilter__activelabel
{
  background-color: rgba(255,255,255, .3);
  padding: 5px 25px 5px 10px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  color: #fff;
  border-radius: 5px;
}
.productfilter__activeremove:before
{
  content: "\f00d";
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
  color: #fff;
  position: absolute;
  right: 8px;
  top: 6px;
  font-family: 'FontAwesome';
}
.productfilter:last-child
{
  padding-bottom: 0;
}

#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter
{
  border-bottom:0;
  background-color: #333;
  margin-bottom: 20px;
}
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color:#fff;
  font-size: 14px;
}
#filterSideNav ul li
{
  border-bottom:0!important;
}
.productfilter-title
{
  padding:0;
  margin: 10px 0;
  display: block;
  color:#fff;
  font-size: 14px;
  font-weight: 600;
}
.productfilter-title:hover,
.productfilter-title:focus
{
  color:#fff;
}
.productfilter-title[data-bs-toggle="collapse"]:after
{
  content: "\f106";
  font-family: FontAwesome;
  float: right;
  font-size: 16px;
  position: relative;
  top: -3px;
}

.productfilter-title[data-bs-toggle="collapse"].collapsed:after
{
  content: "\f107";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: -5px 0 0 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-content: flex-start;
}
.productfilter--optionlist li.productfilter--optionlistvalue
{
  border-top: none;
}
.productfilter--optionlist li input
{
  margin-top: 6px;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
  margin-bottom: 0px;
  font-size: 14px;
  color: #fff;
}
.productfilter--select
{
  width: 100%;
  background-color:#fff;
  padding: 5px;
  border-color: transparent;
  border-radius: .25rem;
  color: #495057;
  font-weight: 300;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
a.collapsebtn[aria-expanded="false"]:before{
  content:"Filter";
}

a.collapsebtn[aria-expanded="true"]:before{
  content:"Filter inklappen";
}
.twocol .productsgrid .col-lg-4,
.twocol .productsgrid .col-sm-6
{
  flex: 0 0 100%;
  max-width: 100%;
}
.widget-inlinereview h2
{
  font-size: 21px;
}
.widget-inlinemultiplereviews--bgwhite
{
  background-color: #fff;
  padding: 20px;
}
.widget-inlinereview__comment
{
  background-color: #eee;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview__comment:after
{
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 30px;
  border-left: 10px solid transparent;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
}
.widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
  .widget-inlinereview__comment > *:before
, .widget-inlinereview__comment > *:after
{
  display: inline-block;
  content: '"';
}
.widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview__more
{
  margin-top: 10px;
}

.widget-inlinereview .fr-rating
, .reviewtotals .fr-rating
{
  font-size: 24px;
  font-weight: 600;
}
.widget-inlinereview .fr-recensie
, .reviewtotals .fr-recensie
{
    color: #000;
    margin-right: 5px;
}
.widget-inlinereview .fr-rating-txt,
.widget-inlinereview .fr-rating-txt a,
.reviewtotals .fr-rating-txt,
.reviewtotals .fr-rating-txt a
{
    display: block;
    margin: -5px 0 0 0;
    color: #1a171b;
    font-size: 12px;
}

.widget-fullwidthimage a
{
  display: block;
  text-decoration: none;
}
.widget-fullwidthimage img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.category__banner
{
  background-color: #5e5045;
}
.category__banner h2,
.category__banner p
{
  color:#fff;
}

.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}
.categoryintro img
{
  border-radius: 15px;
}
@media (max-width: 565px)
{
  .categoryintro img
  {
    display: none;
  }  
}
.usps__totziens a
{
    color: #1a171b!important;
    padding: 10px;
    font-size: .9rem;
    font-family: 'Titillium Web', sans-serif;
}
.usps__totziens i 
{
    color: #e31c76!important;
}
@media (max-width: 992px)
{
  .usps__totziens
  {
    min-height: 35px;
    position: relative;
  }
  .usps__totziens .usp_item
  {
    display: inline-block;
    width: 100%;
    float: left;
    position: absolute;
    top: 0;
    left: 50%;
    width: 240px;
    margin-left: -120px;
    opacity: 0;
  }
}

.fr_carousel
{
  border-radius: 0.7rem;
  overflow: hidden;
}

.carousel-indicators
{
  margin-bottom: 0;
}

.carousel-indicators [data-bs-target]
{
  background-color: #1a171b ;
}

.carousel-indicators .active
{
  background-color: #e31c76;
}
.widget-text
{
  border-radius: 0.7rem;
}
.widget-top10 h3
{
  margin: -15px -1rem 1rem -1rem;
  padding: 1.3rem;
  background-color: #1a171b;
  color: #fff;
  font-size: 27px;
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  text-transform: uppercase;
  text-align: center;
}
.widget-top10 h3 strong,
.widget-top10 h3 b
{
  color: #e31c76;
  font-weight: 700;
}
.widget-top10 .ordered
{
  margin-bottom: 0;
}
.widget-top10 .ordered li:nth(1)
{
  color: #1a171b ;
  font-weight: 600;
}
.widget-top10 .ordered li:nth-child(1)::before,
.widget-top10 .ordered li:nth-child(2)::before,
.widget-top10 .ordered li:nth-child(3)::before
{
  margin-right: 2px;
  background: url('../web/img/icon-star.svg') no-repeat -1px 0px;
  background-size: 24px;
  color: #fff;
  font-weight: 600;
  padding: 4px 7px;
  position: relative;
  left: -26px;
}
.widget-top10 .ordered li:nth-child(1)::before
{
  content: "1";
  font-size: 13px;
}
.widget-top10 .ordered li:nth-child(2)::before
{
  content: "2";
  font-size: 13px;
}
.widget-top10 .ordered li:nth-child(3)::before
{
  content: "3";
  font-size: 13px;
}

.widget-top10 .ordered li:nth-child(1)::marker,
.widget-top10 .ordered li:nth-child(2)::marker,
.widget-top10 .ordered li:nth-child(3)::marker
{
  color: #fff;
}

.widget-top10 .ordered li::marker
{

}
.widget-top10 .ordered li a 
{
  color: #1a171b ;
  line-height: 32px;
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
}
.widget-top10 .ordered li:nth-child(1) a,
.widget-top10 .ordered li:nth-child(2) a,
.widget-top10 .ordered li:nth-child(3) a
{
  position: relative;
  left: -26px;
  font-weight: 600;
}
.widget__recentblogs
{
  padding: 80px 0px;
  margin-bottom: 30px;
}
.widget__recentblogs a p
{
  color:#333333;
}
.widget__recentblogs a.card
{
  transition: all .2s ease-in-out 0s;
}
.widget__recentblogs a.card:hover
{
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  -moz-box-shadow: 0 0 20px rgba(0,0,0,.2);
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,.2);
}
.widget__recentblogs a:hover
{
  text-decoration: none;
  color:#333333;
}
.widget__recentblogs h2
{
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  margin-top: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
/* widget faq */
.widget-faq
{
  border-radius: .7rem;
  border: 1px solid rgba(0, 0, 0, .125);
  overflow: hidden;
}
.widget-faq .card
{
  margin-top: -2px;
  border-radius: 0;
  text-align: left;
  border-right: 0;
  border-left: 0;
}
.widget-faq .card:last-child
{
  border-bottom: 0;
}
.widget-faq .card-header
{
  background-color: #fff;
}

.widget-faq .card .card-header
{
  padding:0;
  margin-bottom: 0;
}
.widget-faq .btn-link
{
  color:#1a171b;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
  font-size: 21px;
  border-radius: 0;
  font-weight: 600;
}
.widget-faq .card-header .btn:after
{
  font-family: 'FontAwesome';
  content: "\f147";
  float: right;
  font-size: 21px;
  color: #e31c76;
  font-weight: normal;
}
.widget-faq .card-header .btn.collapsed:after
{
  content: "\f196";
}
.page--blog aside
{
  background-color: #fff;
  padding:20px;
  border-radius: .25rem;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  border: 1px solid rgba(0,0,0,.125);
}
.page--blog #sidenav > ul > li.active
{
  margin-top: 20px;
}

.widget-innerlinks .badge-light:hover,
.widget-innerlinks .badge-light:focus
{
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.widget__bloglist a:hover
{
  text-decoration: none!important;
}
.widget__bloglist h5
{
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

/* banner */
.widget-image
{
  border-radius: 0.7rem;
  overflow: hidden;
}
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-button
{
  display: inline-block;
  margin-right: 1rem;
}

.widget-login .wrdauth__loginbutton
{
  display: block;
}

.twocol .col-lg-6
{
  width: 50%;
  float: left;
}
.twocol.bg-dark p a
{
  color: #fff;
  text-decoration: underline;
}

@media(max-width: 992px)
{
  .twocol .col-lg-6
  {
    float: none;
    width: 100%;
  }
}
@media(min-width: 992px)
{
  .twocolimgright .col-lg-6 img
  {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.categorylisttag__list
{
  padding:0;
}
.widget-categorylist
{
  background: #211e1e;
  padding: 20px;
}
.widget-categorylist h2
{
  font-size: 21px;
  color: #fff;
}
.categorylist__list,
.subcategorylist__list,
.subsubcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.categorylist__list li a:hover,
.categorylist__list li a:focus
{
  color:#fff;
}
.subcategorylist__list,
.subsubcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  font-size: 14px;
}

.categorylist__listitem
{
  list-style-type: none;
  padding: 1px 0;
}
.categorylist__list > li.categorylist__listitem
{
  display: none;
}
.categorylist__list > ul > li > a 
{
  margin-bottom: 10px;
}
.categorylist__list > li.categorylist__listitem.selected
{
  display: block;
  font-size: 18px;
  font-weight: 400;
}
.categorylist__list > li.categorylist__listitem.active > a
{
  color: #e31c76;
}
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  list-style-type: none;
  padding: 0 0 0 10px;
}
li.selected > .subcategorylist__list,
li.selected > .subsubcategorylist__list
{
  display: block;
}
.subcategorylist__listitem.active > a,
.subsubcategorylist__listitem.active > a
{
  text-decoration:none;
  color: #e31c76;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink,
.subsubcategorylist__listitemlink
{
  color: #fff;
}
.categorylist__listitemlink:hover,
.categorylist__listitemlink:focus,
.subcategorylist__listitemlink:hover,
.subcategorylist__listitemlink:focus,
.subsubcategorylist__listitemlink:hover,
.subsubcategorylist__listitemlink:focus
{
  color: #fff;
}
.widget-tagcloud
{
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 380px;
}
.widget-tagcloud ul
{
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 10px -7.5px;
  overflow: hidden;
  height: 240px;
}
.widget-tagcloud li
{
  display: table-row;
}
.widget-tagcloud li:before
{
  content: '\f105';
  width: 1.5em;
  font-size: 16px;
  line-height: 24px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #333;
}
.widget-tagcloud .less
{
  display: none;
}
.widget-tagcloud .showMore,
.widget-tagcloud .showLess
{
  cursor: pointer;
}

/* ========================================================================

   Verlanglijstje

*/

/* toon verlanglijstje verwijdering alleen op verlanglijst pagina */
html.totziens--verlanglijstje .site__content
{
  margin-top: 1.5rem;
}
html:not(.totziens--verlanglijstje) .totziens--only-verlanglijstje
{
  display:none;
}
.totziens__removefromlist label
{
  cursor:pointer;
}
.totziens__removefromlist .webshop-product__favorite
{
  visibility: hidden;
  width:0;
}
.totziens__removefromlist__text
{
  margin-left:5px;
}

.top-indicator {
    right: 0;
    top: 1rem;
    bottom: inherit;
    left: inherit;
    margin-right: 1rem;
}
.overflow {
    position: relative;
    overflow: hidden;
}
.zoom img {
    transition: all 0.2s linear;
}
.zoom:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}
html:not(.webshop--iscategorypage) .ordering-container,
html.webshop--ishomepage .ordering-container
{
  display: none;
}
html.webshop--istagpage .ordering-container
{
  display: block;
}