@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");
@media (min-width: 992px) {
  .scroll-down #header,
  .scroll-up #header {
    transform: none;
    background-color: #f5f5f3 !important;
  }
  #sticky {
    margin-bottom: 15px;
  }
  #sticky .searchform .form-control {
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
    min-width: 295px;
  }
  #sticky .searchform .btn-link {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
    background: #fff;
  }
  #sticky .searchform .btn-link i {
    color: #e31c76 !important;
  }
  .scroll-up #sticky,
  .scroll-down #sticky {
    position: sticky;
    top: 27px;
    z-index: 1020;
    background-color: #1a171b !important;
  }
  .scroll-up #sticky:before,
  .scroll-down #sticky:before {
    content: "";
    height: 37px;
    background-color: #f5f5f3 !important;
    width: 100%;
    top: -37px;
    position: absolute;
  }
  .scroll-up #sticky:after,
  .scroll-down #sticky:after {
    content: "";
    height: 20px;
    background-color: #f5f5f3 !important;
    width: 100%;
    bottom: -20px;
    position: absolute;
  }
  .scroll-up #sticky #mainnav .navbar-nav .nav-item .logo,
  .scroll-down #sticky #mainnav .navbar-nav .nav-item .logo {
    background-color: #1a171b !important;
  }
}
@media (max-width: 992px) {
  #header {
    margin-bottom: 15px;
  }
  .scroll-down #header {
    transform: translate3d(0, -100%, 0);
  }
  .scroll-up #header {
    transform: none;
    position: sticky;
    top: 0;
    z-index: 1020;
    background-color: #f5f5f3 !important;
  }
  .scroll-up #header:after {
    content: "";
    height: 20px;
    background-color: #f5f5f3 !important;
    width: 100%;
    bottom: -20px;
    position: absolute;
  }
  .scroll-up #header #sticky {
    background-color: #1a171b !important;
  }
}
header {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .topleftpanel ul li a {
  color: #1a171b !important;
  padding: 10px;
  font-size: 0.9rem;
  font-family: "Titillium Web", sans-serif;
}
header .topleftpanel ul li a i {
  color: #e31c76 !important;
}
header .toprightpanel i {
  font-size: 19px;
  position: relative;
  top: 1px;
  color: #e31c76 !important;
}
header .toprightpanel .btn {
  font-size: 0.9rem;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  color: #1a171b !important;
}
@media (max-width: 1200px) {
  header .toprightpanel .btn {
    font-size: 0.8rem;
  }
}
@media (max-width: 992px) {
  header {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  header .navbar {
    min-height: 59px;
  }
  header .navbar .logo {
    position: absolute;
    left: -15px;
    top: -29px;
  }
  header .navbar .payoff {
    position: absolute;
    left: 32px;
    font-size: 13px;
    bottom: -22px;
    z-index: 1;
    color: #e31c76 !important;
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
  }
  header.bg-light {
    background-color: #f5f5f3 !important;
  }
}
@media (max-width: 767px) {
  header .navbar {
    padding: 0;
  }
  header .myaccount {
    display: none;
  }
}
@media (max-width: 565px) {
  header .navbar .logo {
    left: 0;
  }
  header .container-xl {
    max-width: 100%;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .navbar #mainnav .navbar-nav li .nav-link {
    padding: 0.5rem;
  }
}

.navbar-brand {
  font-size: 14px;
  margin-right: 5px;
  padding: 0;
}

.navbar-toggler {
  border: none;
}

@media (max-width: 575px) {
  #navbar-wrapper .container-xl {
    padding: 0 5px;
  }
}

#mainnav {
  width: 100%;
  /* Dim the screen on hover of menu item and focus on dropdown  */
}
#mainnav .navbar-nav .nav-item .logo img {
  margin-top: -37px;
}
#mainnav .navbar-nav .nav-item .payoff {
  position: absolute;
  left: 33px;
  font-size: 13px;
  bottom: -31px;
  z-index: 1;
  color: #e31c76 !important;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  text-transform: none;
}
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu {
  border-radius: 15px;
  top: 100%;
  min-width: 300px;
  margin-top: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #1a171b !important;
}
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li:hover > a, #mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li:focus > a {
  background-color: #1a171b !important;
  color: #e31c76 !important;
}
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li a {
  font-size: 14px;
  color: #ffffff !important;
}
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li a i {
  position: relative;
  top: 7px;
}
#mainnav .navbar-nav .nav-item.dropdown .dropdown-menu li a.active {
  background-color: #1a171b !important;
  color: #e31c76 !important;
}
@media (min-width: 1200px) {
  #mainnav .navbar-nav .nav-item.dropdown:hover > .dropdown-menu, #mainnav .navbar-nav .nav-item.dropdown:focus > .dropdown-menu {
    display: block;
  }
}
#mainnav .navbar-nav li ul li ul.dropdown-menu {
  left: 100%;
  top: -3px !important;
}
#mainnav .navbar-nav .nav-link {
  color: #fff;
  transition: none;
  padding: 0.5rem 1rem;
  height: 100%;
  line-height: 42px;
  font-family: "Titillium Web", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}
.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}