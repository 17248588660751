html.webshop--ishomepage main {
  padding: 0;
}
@media (max-width: 992px) {
  html.webshop--ishomepage main .fr_carousel {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  html.webshop--ishomepage main .widget-image {
    margin-bottom: 20px;
  }
}
html.webshop--ishomepage main .webshop-products-mini .product_listing {
  padding-right: 15px;
  padding-left: 15px;
}