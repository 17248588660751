@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");
.wh-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0 none;
}
.wh-pagination li {
  display: inline-block;
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 3px;
}
.wh-pagination li + li {
  margin-left: 10px;
}
.wh-pagination span, .wh-pagination a {
  display: block;
  padding: 5px 12px;
}
.wh-pagination a {
  color: #1a171b;
  text-decoration: none;
}
.wh-pagination a:hover {
  border-color: #ccc;
}
.wh-pagination li.active {
  border: 1px solid #e31c76 !important;
}
.wh-pagination li.active a {
  background-color: #e31c76 !important;
  color: #fff;
}
.wh-pagination span::before {
  content: "...";
  display: inline-block;
}